
import { User } from "@/store/user/types";
import { ContentItem } from "@/types";
import { Component, Vue } from "vue-property-decorator";

import { USER_GETTERS } from "@/store/user/getters";
import { ROOT_ACTIONS } from "@/store/actions";
import { addDoc, collection, documentId, getDoc, getDocs, query, where } from "firebase/firestore";
import { firestore } from "@/firebase";

@Component
export default class CreateContentAssignment extends Vue {

  contentItems: ContentItem[] = [];
  participants: Partial<User[]> = [];

  loading = false;

  inputs = {
    selectedItem: null as unknown as ContentItem,
    selectedUser: null as unknown as User,
  }

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  async getUsers() {
    const users = await getDocs(query(collection(firestore, 'users')))
    return users.docs.map(e => {
      return {
        ...e.data(),
        id: e.id,
        fullName: e.data().firstname + ' ' + e.data().lastname
      } as User;
    })
  }

  async mounted() {
    let items = await getDocs(query(collection(firestore, 'content-items'), where('archived', '==', false)));
    this.contentItems = items.docs.map(e => {
      return {
        ...e.data(),
        id: e.id,
      } as ContentItem;
    })

    let users: User[] = await this.getUsers();

    if (this.user.type !== 'ADMIN') {
      const programs = await getDocs(query(collection(firestore, 'programs'), where('coach', '==', this.user.id), where('isArchived', '==', false)))
      const myParticipants = programs.docs.map(e => e.data().participant)
      users = users.filter(e => myParticipants.includes(e.id))
    }

    this.participants = users;
  }

  resetFields() {
    this.inputs = {
      selectedItem: null as unknown as ContentItem,
      selectedUser: null as unknown as User,
    }
  }

  async submit() {
    try {
      this.loading = true;

      await addDoc(collection(firestore, 'content-assignments'), {
        contentId: this.inputs.selectedItem.id,
        user: this.inputs.selectedUser.id,
        requestedBy: this.user.id,
        completed: false,
      })

      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'OK'
      })
      this.$emit('refresh')
      this.resetFields();
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading = false;
    }
  }

}
