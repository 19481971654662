
import { ROOT_ACTIONS } from "@/store/actions";
import { Component, Vue } from "vue-property-decorator";
import { ContentAssignment, ContentItem, Program } from "@/types";

import { User } from "@/store/user/types";
import CreateContentAssignment from "@/components/cards/CreateContentAssignment.vue";
import { USER_GETTERS } from "@/store/user/getters";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { collection, deleteDoc, doc, getDocs, query, where, documentId, getDoc } from "firebase/firestore";
import { firestore } from "@/firebase";

@Component({
  components: {
    CreateContentAssignment,
    ConfirmationDialog
  }
})
export default class Index extends Vue {
  items = {} as Record<string, ContentItem>;
  assignments: Record<string, ContentAssignment[]> = {};

  selectedAssignment = '';

  dialogs = {
    deleteAssignment: false,
  }

  loading = true;
  loadingDeleteAssignment = false;

  programs = [] as Program[];
  users: Record<string, User> = {};


  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  async deleteAssignment() {
    try {
      this.loadingDeleteAssignment = true;
      await deleteDoc(doc(firestore, 'content-assignments', this.selectedAssignment.toString()));
      await this.fetchAssignments();
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: 'error'
      })
    } finally {
      this.loadingDeleteAssignment = false;
      this.dialogs.deleteAssignment = false;
    }
  }



  async fetchAssignments() {
    let items = await getDocs(query(collection(firestore, 'content-items')));

    items.docs.forEach(e => {
      Object.assign(this.items, { [e.id]: { ...e.data(), id: e.id } as ContentItem })
    })

    let users: User[] = [];

    if (this.user.type === 'ADMIN') {
      let fetchedUsers = await getDocs(query(collection(firestore, 'users'), where('type', '==', 'PARTICIPANT')));
      users = fetchedUsers.docs.map(e => {
        return {
          id: e.id,
          ...e.data()
        } as User
      })
    } else {
      const programs = await getDocs(query(collection(firestore, 'programs'), where('coach', '==', this.user.id), where('isArchived', '==', false)));
      for(let program of programs.docs){
        let fetchedUser = await getDoc(doc(firestore, 'users', program.data().participant));
        users.push({
          id: fetchedUser.id,
          ...fetchedUser.data()
        } as User)
      }
    }

    users.forEach(u => Object.assign(this.users, { [u.id]: u }))

    if (users.length === 0) {
      this.loading = false;
      return;
    }
    let fetchedAssignments = await getDocs(query(collection(firestore, 'content-assignments')));
    const assignments = fetchedAssignments.docs.map(e => {
      return {
        id: e.id,
        ...e.data(),
      } as ContentAssignment
    })


    this.assignments = {};
    assignments.forEach(a => {
      if (!this.assignments[a.user]) {
        Object.assign(this.assignments, { [a.user]: [a] })
      } else {
        this.assignments[a.user].push(a)
      }
    })

    this.$forceUpdate();
    this.loading = false;
  }

  async mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: this.$t('assignedContent'), target: '/admin/content-assignment' }])
    await this.fetchAssignments();


  }
}

